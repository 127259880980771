var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table-overview',{attrs:{"title":"Applied Rules","icon":"far fa-fw fa-globe","store":"refinementRule/submissions","headers":[
    { text: 'Date', value: 'created_at' },
    { text: 'Name of field', value: 'target', sortable: false },
    { text: 'Old value', value: 'before', sortable: false },
    { text: 'New value', value: 'after', sortable: false },
    {
      text: 'Details',
      align: 'center',
      sortable: false,
      class: 'v-table-th--icon',
      rolesOrPermissions: 'submissions_view'
    }
  ],"force-initial-sort":"created_at","descending":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('refinement-rule-applied-table-row',{attrs:{"applied-id":item}})}}])})}
var staticRenderFns = []

export { render, staticRenderFns }