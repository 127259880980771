<template>
  <tr>
    <td>{{ refinementRule.name }}</td>
    <td>
      <v-chip
        v-for="(refinement, index) in refinementRule.refinements"
        :key="index"
        label
      >
        {{ refinementTypes[refinement.type] }}
        <span v-if="refinement.type === 'REPLACE'" class="ml-2 grey--text">
          [&nbsp;<kbd
            v-for="(refinementAlpha, i) in refinement.alpha.slice(0, 3)"
            :key="i"
            class="white-space-initial"
          >
            {{ refinementAlpha }}
          </kbd>
          <kbd v-if="refinement.alpha.length > 3" class="white-space-initial">
            ... </kbd
          >&nbsp;=>&nbsp;<kbd class="white-space-initial">
            {{ refinement.beta }} </kbd
          >&nbsp;]
        </span>
      </v-chip>
    </td>
    <td class="text-sm-right">
      <v-icon :color="refinementRule.isActive ? 'green' : 'grey'">
        {{ refinementRule.isActive ? "check" : "clear" }}
      </v-icon>
    </td>
    <td>
      <v-btn
        icon
        :to="{
          name: 'refinement-rules.show',
          params: { refinementRuleId: refinementRule.id }
        }"
      >
        <v-icon>far fa-search</v-icon>
      </v-btn>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters: mapAuthGetters } = createNamespacedHelpers("auth");
const { mapGetters: mapRefinementRulesGetters } = createNamespacedHelpers(
  "refinementRules"
);

export default {
  name: "refinement-rule-table-row",
  props: {
    refinementRuleId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    refinementTypes: window.config.enums.submissionRuleRefinementTypes
  }),
  computed: {
    ...mapAuthGetters(["hasRolesOrPermissions"]),
    ...mapRefinementRulesGetters([FIND_BY_ID]),
    refinementRule() {
      return this[FIND_BY_ID](this.refinementRuleId);
    }
  }
};
</script>
<style scoped>
.white-space-initial {
  white-space: initial;
}
</style>
