<template>
  <table-overview
    v-if="!showChild"
    title="Refinement Rules"
    store="refinementRules"
    :headers="[
      { text: 'Name', value: 'name' },
      { text: 'Refinements', value: 'refinements', sortable: false },
      { text: 'Active', align: 'right', value: 'is_active', width: '100px' },
      {
        text: 'Actions',
        align: 'right',
        value: 'name',
        sortable: false,
        width: '100px'
      }
    ]"
    :actions="actions"
    searchable
    force-initial-sort
  >
    <refinement-rule-table-row
      slot="table-row"
      slot-scope="{ item }"
      :refinement-rule-id="item"
    />
  </table-overview>
  <router-view v-else />
</template>
<script type="text/babel">
import TableOverview from "../../components/generic/overview/TableOverview";
import refinementRulesModule from "@/store/modules/refinement-rules";
import RefinementRuleTableRow from "../../components/refinement-rule/RefinementRuleTableRow";
import { mapOverviewLifecycleMethods } from "../helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "refinementRules",
  refinementRulesModule
);

export default {
  name: "refinement-rules-view",
  components: {
    RefinementRuleTableRow,
    TableOverview
  },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "refinement-rules.create" },
        rolesOrPermissions: "refinement_rule_create"
      }
    ]
  }),
  computed: {
    showChild() {
      return (
        this.$route.matched.filter(route => route.name !== undefined).length > 1
      );
    }
  },
  ...overviewLifecycleMethods
};
</script>
