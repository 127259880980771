<template>
  <div>
    <refinement-rule :match-data="triggerGroupMatchData" />
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import RefinementRule from "@/components/refinement-rule/RefinementRule";
const { mapGetters } = createNamespacedHelpers("refinementRule");

export default {
  name: "refinement-Rule-dashboard-view",
  components: {
    RefinementRule
  },
  computed: {
    ...mapGetters([]),
    triggerGroupMatchData() {
      return this.$store.state.refinementRule.ruleTriggerGroups
        .ruleTriggerGroupMatchData;
    }
  },
  data: () => ({})
};
</script>
