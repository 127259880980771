var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.showChild)?_c('table-overview',{attrs:{"title":"Refinement Rules","store":"refinementRules","headers":[
    { text: 'Name', value: 'name' },
    { text: 'Refinements', value: 'refinements', sortable: false },
    { text: 'Active', align: 'right', value: 'is_active', width: '100px' },
    {
      text: 'Actions',
      align: 'right',
      value: 'name',
      sortable: false,
      width: '100px'
    }
  ],"actions":_vm.actions,"searchable":"","force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
  var item = ref.item;
return _c('refinement-rule-table-row',{attrs:{"refinement-rule-id":item}})}}],null,false,2678438885)}):_c('router-view')}
var staticRenderFns = []

export { render, staticRenderFns }