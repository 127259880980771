<template>
  <table-overview
    title="Applied Rules"
    icon="far fa-fw fa-globe"
    store="refinementRule/submissions"
    :headers="[
      { text: 'Date', value: 'created_at' },
      { text: 'Name of field', value: 'target', sortable: false },
      { text: 'Old value', value: 'before', sortable: false },
      { text: 'New value', value: 'after', sortable: false },
      {
        text: 'Details',
        align: 'center',
        sortable: false,
        class: 'v-table-th--icon',
        rolesOrPermissions: 'submissions_view'
      }
    ]"
    force-initial-sort="created_at"
    descending
  >
    <refinement-rule-applied-table-row
      slot="table-row"
      slot-scope="{ item }"
      :applied-id="item"
    />
  </table-overview>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import TableOverview from "@/components/generic/overview/TableOverview";
import RefinementRuleAppliedTableRow from "@/components/refinement-rule/submission/RefinementRuleAppliedTableRow";

const { mapGetters } = createNamespacedHelpers("refinementRule");

export default {
  name: "refinement-Rule-applied-view",
  components: { RefinementRuleAppliedTableRow, TableOverview },
  data: () => ({
    triggerGroupMatchData: undefined
  }),
  computed: {
    ...mapGetters(["id"])
  }
};
</script>
