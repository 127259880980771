<template>
  <div>
    <span class="title">
      Actions
    </span>
    <v-list two-line>
      <draggable v-model="refinements">
        <v-list-tile
          v-for="(refinement, index) in refinements"
          :key="index"
          class="v-list-border-top"
        >
          <refinement :refinement="refinement" :refinement-index="index" />
        </v-list-tile>
      </draggable>
    </v-list>
    <v-flex text-xs-center>
      <v-btn
        v-if="!isRefinementDialogOpen"
        color="primary"
        @click.native="openRefinement"
      >
        <v-icon>add</v-icon>
        Add action
      </v-btn>
    </v-flex>
    <v-dialog v-model="isRefinementDialogOpen" persistent max-width="600">
      <v-card>
        <v-layout>
          <v-flex xs8>
            <v-card-title class="headline">
              Add a refinement
            </v-card-title>
          </v-flex>
          <v-flex xs4>
            <v-switch
              v-if="refinement.type === 'REPLACE'"
              v-model="refinement.isCaseSensitive"
              color="primary"
              label="Is Case Sensitive"
            />
          </v-flex>
        </v-layout>
        <v-card-text>
          <b-select
            v-model="refinement.type"
            :items="refinementTypesSelect"
            label="Type"
          />
          <template v-if="refinement.type === 'REPLACE'">
            <v-layout
              row
              v-for="(refinementAlpha, index) in refinement.alpha"
              :key="index"
            >
              <v-flex xs10>
                <v-text-field
                  :value="refinement.alpha[index]"
                  :label="index > 0 ? undefined : 'From'"
                  v-on:input="value => setFrom(index, value)"
                  required
                  hint="Exact match. In order to allow other text - including spaces - before or after the needle use the wildcard ( * ) character. </br> E.g: </br> - '0475' does not match value '04758...' or 'bla bla 0475', only exactly '0475' </br> - '*0475' matches 'bla bla 0475' as well as '0475', but not '04758...' </br> - '0475*' matches '04758...' as well as '0475', but not 'bla bla 0475' </br> - '*0475*' matches '04758...', 'bla bla 0475' and '0475'"
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-btn
                  icon
                  @click="removeFrom(index)"
                  v-if="refinement.alpha.length > 1"
                >
                  <v-icon>far fa-times</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs1>
                <v-btn
                  icon
                  v-if="refinement.alpha.length === index + 1"
                  @click="addFrom"
                >
                  <v-icon>far fa-plus</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
          <b-text-field
            v-if="refinement.type === 'REPLACE'"
            v-model="refinement.beta"
            label="To"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn flat @click.native="isRefinementDialogOpen = false">
            Nevermind
          </v-btn>
          <v-btn
            color="primary"
            flat
            :disabled="refinement.type === null"
            @click.native="storeRefinement"
          >
            Add
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script type="text/babel">
import { cloneDeep, forOwn } from "lodash";
import draggable from "vuedraggable";

import { createNamespacedHelpers } from "@/store/helpers";
import Refinement from "@/components/refinement-rule/Refinement";
import BTextField from "@/components/generic/BTextField";
import BSelect from "@/components/generic/BSelect";

const { mapComputed } = createNamespacedHelpers("refinementRule");

export default {
  name: "refinement-rule-refinements",
  components: {
    BSelect,
    BTextField,
    Refinement,
    draggable
  },
  data: () => {
    return {
      refinement: {
        type: null,
        alpha: null,
        beta: null,
        isCaseSensitive: true
      },
      isRefinementDialogOpen: false,
      dialogEditIndex: null
    };
  },
  computed: {
    ...mapComputed(["refinements"]),
    refinementTypes() {
      return window.config.enums.submissionRuleRefinementTypes;
    },
    refinementTypesSelect() {
      let statuses = [];
      forOwn(this.refinementTypes, (property, value) =>
        statuses.push({ text: property, value: value })
      );
      return statuses;
    }
  },
  methods: {
    openRefinement() {
      this.refinement = cloneDeep(this.refinement);
      this.refinement.type = "REPLACE";
      this.refinement.alpha = [""];
      this.refinement.beta = null;
      this.isRefinementDialogOpen = true;
      this.dialogEditIndex = null;
    },
    storeRefinement() {
      if (this.dialogEditIndex === null) {
        this.$store.commit("refinementRule/addRefinement", this.refinement);
      } else {
        this.$store.commit("refinementRule/setRefinement", {
          refinementId: this.dialogEditIndex,
          refinement: this.refinement
        });
        this.dialogEditIndex = null;
      }
      this.isRefinementDialogOpen = false;
    },
    editRefinement(index) {
      this.refinement = this.refinements[index];
      this.dialogEditIndex = index;
      this.isRefinementDialogOpen = true;
    },
    removeRefinement(index) {
      this.$store.commit("refinementRule/deleteRefinement", index);
    },
    addFrom() {
      this.refinement.alpha = [...this.refinement.alpha, ""];
    },
    removeFrom(index) {
      const alpha = [...this.refinement.alpha];
      alpha.splice(index, 1);
      this.refinement.alpha = alpha;
    },
    setFrom(index, value) {
      const alpha = [...this.refinement.alpha];
      alpha.splice(index, 1, value);
      this.refinement.alpha = alpha;
    }
  }
};
</script>
<style scoped>
.v-list-border-top:not(:first-of-type) {
  border-top: 1px solid lightgray;
}
</style>
