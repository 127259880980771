<template>
  <div class="pa-3">
    <b-text-field v-model="name" label="Name" required />
    <b-select
      v-model="targets"
      :items="['NAME_LAST', 'NAME_FIRST', 'EMAIL', 'TELEPHONE', 'DESCRIPTION']"
      label="Targets"
      multiple
      required
      deletable-chips
    />
    <b-checkbox v-model="isActive" label="Is active" />
    <refinement-rule-refinements />

    <div class="refinement-tester container fluid my-4">
      <span class="title text-uppercase secondary--text">
        Test the refinements
      </span>
      <v-layout row class="refinement-tester__test">
        <v-flex xs5>
          <v-text-field
            label="Before"
            v-model="refinementTestBefore"
          ></v-text-field>
        </v-flex>

        <v-flex xs2 class="text-xs-center">
          <v-btn
            secondary
            @click="testRefinement"
            :loading="refinementTestLoading"
            :disabled="refinementTestLoading || isChanged"
            >TEST
          </v-btn>
        </v-flex>

        <v-flex xs5>
          <v-text-field
            label="After"
            :value="refinementTestAfter"
            readonly
          ></v-text-field>
        </v-flex>
      </v-layout>
    </div>

    <submission-triggers
      store="refinementRule"
      :entity-name="entityName"
      :match-data="matchData"
    />
  </div>
</template>

<script type="text/babel">
import SubmissionTriggers from "../submission-trigger/SubmissionTriggers";
import RefinementRuleRefinements from "./RefinementRuleRefinements";
import { createNamespacedHelpers } from "@/store/helpers";
import BTextField from "@/components/generic/BTextField";
import BSelect from "@/components/generic/BSelect";
import BCheckbox from "@/components/generic/BCheckbox";
import refinementRuleApi from "@/api/refinement-rule";

const { mapComputed } = createNamespacedHelpers("refinementRule");

export default {
  name: "refinement-rule",
  inject: ["$validator"],
  components: {
    BCheckbox,
    BSelect,
    BTextField,
    RefinementRuleRefinements,
    SubmissionTriggers
  },
  props: {
    matchData: {
      type: Object,
      default: undefined
    }
  },
  data: () => ({
    entityName: "refinementRule",
    submissionIdToCheck: null,
    triggerGroupMatchData: undefined,
    refinementTestBefore: null,
    refinementTestAfter: null,
    refinementTestLoading: false
  }),
  computed: {
    ...mapComputed([
      "id",
      "name",
      "refinements",
      "isActive",
      "triggers",
      "targets",
      "isChanged"
    ]),
    refinementTypesSelect() {
      return Object.entries(
        window.config.enums.submissionRuleRefinementTypes
      ).map(o => ({ text: o[1], value: o[0] }));
    }
  },
  methods: {
    async testRefinement() {
      this.refinementTestLoading = true;
      try {
        const { result } = await refinementRuleApi.test(
          this.id,
          this.refinementTestBefore
        );
        this.refinementTestAfter = result;
      } finally {
        this.refinementTestLoading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.refinement-tester {
  background-color: var(--v-primary-lighten5);
}
</style>
