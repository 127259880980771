<template>
  <v-layout>
    <v-list-tile-action class="case-check">
      {{ isCaseSensitive ? "Aa" : "aa" }}
    </v-list-tile-action>
    <v-list-tile-content>
      <v-list-tile-title v-html="refinementTypes[type]" />
      <v-list-tile-sub-title
        v-if="type === 'REPLACE'"
        v-html="`${alpha} => ${beta}`"
      />
    </v-list-tile-content>
    <v-list-tile-action>
      <v-btn icon @click="editRefinement">
        <v-icon>edit</v-icon>
      </v-btn>
      <v-btn icon @click="removeRefinement">
        <v-icon>clear</v-icon>
      </v-btn>
    </v-list-tile-action>
    <v-dialog v-model="isRefinementDialogOpen" max-width="600">
      <v-card>
        <v-layout>
          <v-flex xs8>
            <v-card-title class="headline">
              Add a refinement
            </v-card-title>
          </v-flex>
          <v-flex xs4>
            <v-switch
              v-if="type === 'REPLACE'"
              v-model="isCaseSensitive"
              color="primary"
              label="Is Case Sensitive"
            />
          </v-flex>
        </v-layout>
        <v-card-text>
          <v-select
            v-model="type"
            :items="refinementTypesSelect"
            label="Type"
          />
          <template v-if="refinement.type === 'REPLACE'">
            <v-layout
              row
              v-for="(refinementAlpha, index) in refinement.alpha"
              :key="index"
            >
              <v-flex xs10>
                <v-text-field
                  :value="refinement.alpha[index]"
                  :label="index > 0 ? undefined : 'From'"
                  v-on:input="value => setFrom(index, value)"
                  required
                  hint="Exact match. In order to allow other text - including spaces - before or after the needle use the wildcard ( * ) character. </br> E.g: </br> - '0475' does not match value '04758...' or 'bla bla 0475', only exactly '0475' </br> - '*0475' matches 'bla bla 0475' as well as '0475', but not '04758...' </br> - '0475*' matches '04758...' as well as '0475', but not 'bla bla 0475' </br> - '*0475*' matches '04758...', 'bla bla 0475' and '0475'"
                ></v-text-field>
              </v-flex>
              <v-flex xs1>
                <v-btn
                  icon
                  @click="removeFrom(index)"
                  v-if="refinement.alpha.length > 1"
                >
                  <v-icon>far fa-times</v-icon>
                </v-btn>
              </v-flex>
              <v-flex xs1>
                <v-btn
                  icon
                  v-if="refinement.alpha.length === index + 1"
                  @click="addFrom"
                >
                  <v-icon>far fa-plus</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </template>
          <v-text-field
            v-if="type === 'REPLACE'"
            v-model="beta"
            label="To"
            :required="type === 'REPLACE'"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-layout>
</template>
<script type="text/babel">
import { forOwn } from "lodash";

export default {
  name: "refinement",
  inject: ["$validator"],
  props: {
    refinement: {
      type: Object,
      required: true
    },
    refinementIndex: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    isRefinementDialogOpen: false
  }),
  computed: {
    refinementTypes() {
      return window.config.enums.submissionRuleRefinementTypes;
    },
    refinementTypesSelect() {
      let statuses = [];
      forOwn(this.refinementTypes, (property, value) =>
        statuses.push({ text: property, value: value })
      );
      return statuses;
    },
    type: {
      get() {
        return this.$store.getters["refinementRule/refinementType"](
          this.refinementIndex
        );
      },
      set(newVal) {
        this.$store.commit("refinementRule/setRefinementType", {
          refinementIndex: this.refinementIndex,
          type: newVal
        });
      }
    },
    beta: {
      get() {
        return this.$store.getters["refinementRule/refinementBeta"](
          this.refinementIndex
        );
      },
      set(newVal) {
        this.$store.commit("refinementRule/setRefinementBeta", {
          refinementIndex: this.refinementIndex,
          beta: newVal
        });
      }
    },
    alpha: {
      get() {
        return this.$store.getters["refinementRule/refinementAlpha"](
          this.refinementIndex
        );
      },
      set(newVal) {
        this.$store.commit("refinementRule/setRefinementAlpha", {
          refinementIndex: this.refinementIndex,
          alpha: newVal
        });
      }
    },
    isCaseSensitive: {
      get() {
        return this.$store.getters["refinementRule/refinementIsCaseSensitive"](
          this.refinementIndex
        );
      },
      set(newVal) {
        this.$store.commit("refinementRule/setRefinementIsCaseSensitive", {
          refinementIndex: this.refinementIndex,
          isCaseSensitive: newVal
        });
      }
    }
  },
  methods: {
    editRefinement() {
      this.isRefinementDialogOpen = true;
    },
    removeRefinement() {
      this.$store.commit(
        "refinementRule/deleteRefinement",
        this.refinementIndex
      );
    },
    addFrom() {
      this.alpha = [...this.alpha, ""];
    },
    removeFrom(index) {
      const alpha = [...this.alpha];
      alpha.splice(index, 1);
      this.alpha = alpha;
    },
    setFrom(index, value) {
      const alpha = [...this.alpha];
      alpha.splice(index, 1, value);
      this.alpha = alpha;
    }
  }
};
</script>
<style scoped>
.case-check {
  color: #0653a6;
  min-width: 30px;
}
</style>
