<template>
  <v-card color="white">
    <crud-header
      :title="name"
      type="Refinement Rule"
      store="refinementRule"
      @save-success="
        ({ id }) =>
          $router.push({
            name: 'refinement-rules.show',
            params: { refinementRuleId: id }
          })
      "
    />
    <refinement-rule />
  </v-card>
</template>

<script type="text/babel">
import refinementRuleModule from "@/store/modules/refinement-rule";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("refinementRule");
import { mapCreateViewLifecycleMethods } from "../helpers";
import RefinementRule from "@/components/refinement-rule/RefinementRule";

const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "refinementRule",
  refinementRuleModule
);

export default {
  name: "refinement-rule-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    RefinementRule,
    CrudHeader
  },
  computed: {
    ...mapGetters(["name"])
  },
  ...createViewLifecycleMethods
};
</script>
