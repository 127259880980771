<template>
  <v-dialog v-model="dialog" max-width="600" lazy>
    <v-btn slot="activator" :icon="!hasSubmissionId">
      <v-icon>
        far fa-fw fa-search
      </v-icon>
      {{ submissionId }}
    </v-btn>
    <v-card>
      <v-container>
        <v-text-field
          v-model="internalSubmissionId"
          type="number"
          label="Submission ID to check"
        />
      </v-container>
      <v-card-actions>
        <v-btn flat color="primary" :disabled="loading" @click="dialog = false">
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          :disabled="loading || !hasSubmissionId"
          :loading="loading"
          @click="check"
        >
          Check
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script type="text/babel">
import { isNil } from "lodash";
import api from "../../api";

export default {
  name: "submission-trigger-check-dialog",
  props: {
    value: {
      type: Boolean,
      default: false
    },
    ruleId: {
      type: Number,
      required: true
    },
    submissionId: {
      type: Number,
      default: undefined
    },
    triggerGroups: {
      type: Array,
      required: true
    },
    apiModule: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dialog: false,
    loading: false
  }),
  computed: {
    internalSubmissionId: {
      get() {
        return this.submissionId;
      },
      set(value) {
        this.$emit("update:submissionId", parseInt(value));
      }
    },
    hasSubmissionId() {
      return !isNil(this.submissionId);
    }
  },
  methods: {
    async check() {
      this.loading = true;
      const triggerGroupMatchData = await api[this.apiModule].matches(
        this.ruleId,
        this.submissionId
      );
      this.$emit("update:triggerGroupMatchData", triggerGroupMatchData);
      this.loading = false;
      this.dialog = false;
    }
  }
};
</script>
